@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&family=Press+Start+2P&family=Quicksand:wght@300;400;500;600;700&family=Syncopate:wght@400;700&display=swap");
@import url("https://use.typekit.net/nxj3kdm.css");
@import url("https://fonts.cdnfonts.com/css/kankin");
/*
    @include responsive(large) {

    }

    @include responsive(medium) {

    }

    @include responsive(small) {

    }
*/
html,
body {
  font-size: 16px;
  background-color: #ccf5ac;
}

body,
.container,
#root {
  height: 100vh;
  color: #3b4246;
  cursor: none;
  background-color: #ccf5ac;
}

.rp-main-container {
  height: 100vh;
  background-color: #ccf5ac;
  background-image: url("../Assets/background.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.rp-no-bg {
  background-image: none;
}

.rp-full-height {
  height: 100vh;
}

.rp-privacy-content {
  overflow: scroll;
  height: 85vh;
}

.rp-view {
  height: 100%;
}
.rp-view .rp-section .rp-half-height {
  height: 50%;
}
.rp-view .rp-section .rp-three-fifths-height {
  height: 65%;
}
.rp-view .rp-section .rp-two-fifths-height {
  height: 35%;
}

.rp-border-left {
  border-left: 1px solid #3b4246;
  position: relative;
  z-index: 21;
}

.rp-border-right {
  border-right: 1px solid #3b4246;
  position: relative;
  z-index: 21;
}

.rp-border-top {
  border-top: 1px solid #3b4246;
  position: relative;
  z-index: 21;
}

.rp-border-bottom {
  border-bottom: 1px solid #3b4246;
  position: relative;
  z-index: 21;
}

.is-absolute {
  position: absolute;
}

.is-solid-background {
  background-color: transparent;
}

.rp-cursor {
  background-color: #f93943;
  position: fixed;
  z-index: 1100;
  pointer-events: none;
}
.rp-cursor .rp-cursor-content {
  padding: 10px;
}

.rp-header {
  position: relative;
  height: 100px;
  background-color: #ccf5ac;
  z-index: 1000;
  padding-left: 54px;
  padding-right: 54px;
  cursor: none;
}
.rp-header .rp-divider {
  border-bottom: 1px solid #3b4246;
}
.rp-header .rp-menu {
  padding-right: 30px;
}
.rp-header .rp-menu .rp-menu-item {
  cursor: none;
  text-decoration: none !important;
  color: #3b4246;
  margin: 10px;
}
.rp-header .rp-menu .rp-menu-item .rp-menu-item-text {
  color: #3b4246;
  padding: 5px 10px 5px 10px;
  border: 2px solid transparent;
  background-color: #ccf5ac;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.rp-header .rp-menu .rp-menu-item .rp-menu-item-text:hover, .rp-header .rp-menu .rp-menu-item .rp-menu-item-text:active {
  border: 2px solid transparent;
  border-radius: 30px;
  background-color: #f93943;
  color: #f8f7f9;
}
.rp-header .rp-menu .rp-menu-item .rp-selected {
  border: 2px solid transparent;
  border-radius: 30px;
  background-color: #f93943;
  color: #f8f7f9;
}
.rp-header .rp-menu button {
  cursor: none;
  border-radius: 30px;
}
.rp-header .rp-menu button:hover {
  background-color: #f93943;
}
.rp-header .rp-menu button:hover i {
  color: #f8f7f9;
}

.rp-rounded-icon {
  position: relative;
}
.rp-rounded-icon div {
  pointer-events: none;
}
.rp-rounded-icon img {
  position: relative;
  z-index: 36;
  width: 125px;
  max-width: 125px;
  min-width: 125px;
  object-fit: fill;
  border-radius: 0px;
  transition: all 0.5s ease;
  pointer-events: none;
}
@media screen and (max-width: 1280px) {
  .rp-rounded-icon img {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-rounded-icon img {
    width: 90px;
    max-width: 90px;
    min-width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .rp-rounded-icon img {
    width: 125px;
    max-width: 125px;
    min-width: 125px;
  }
}
.rp-rounded-icon .rp-background {
  width: 125px;
  height: 125px;
  border-radius: 0%;
  background-color: #f93943;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease;
}
@media screen and (max-width: 1280px) {
  .rp-rounded-icon .rp-background {
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-rounded-icon .rp-background {
    height: 90px;
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .rp-rounded-icon .rp-background {
    height: 125px;
    width: 125px;
  }
}

.rp-rounded-icon:hover .rp-rounded-icon-img-shrink {
  transform: scale(0.9);
  border-radius: 0%;
}

.rp-rounded-icon:hover img {
  border: 2px solid #f8f7f9;
}

.rp-rounded-icon:hover .rp-background {
  transform: scale(1.1);
  border-radius: 0%;
}

.rp-round-icon-overlay img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 0%;
}
@media screen and (max-width: 1280px) {
  .rp-round-icon-overlay img {
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-round-icon-overlay img {
    height: 90px;
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .rp-round-icon-overlay img {
    height: 125px;
    width: 125px;
  }
}

.rp-round-icon img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 0%;
  margin-left: 20px;
}
@media screen and (max-width: 1280px) {
  .rp-round-icon img {
    height: 200px;
    width: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-round-icon img {
    height: 200px;
    width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .rp-round-icon img {
    height: 200px;
    width: 200px;
  }
}

.rp-rounded-icon-plain img {
  width: 165px;
  height: 165px;
  object-fit: cover;
  border-radius: 0px;
  margin-left: 20px;
}
@media screen and (max-width: 1280px) {
  .rp-rounded-icon-plain img {
    height: 125px;
    width: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-rounded-icon-plain img {
    height: 105px;
    width: 105px;
  }
}
@media screen and (max-width: 768px) {
  .rp-rounded-icon-plain img {
    height: 125px;
    width: 125px;
  }
}

.rp-round-icon-overlay {
  position: relative;
  transition: 0.5s ease;
}
.rp-round-icon-overlay .rp-colored-bg {
  width: 125px;
  height: 125px;
  border-radius: 0%;
  background-color: #f93943;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.5s ease;
}
@media screen and (max-width: 1280px) {
  .rp-round-icon-overlay .rp-colored-bg {
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-round-icon-overlay .rp-colored-bg {
    height: 90px;
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .rp-round-icon-overlay .rp-colored-bg {
    height: 125px;
    width: 125px;
  }
}
.rp-round-icon-overlay .rp-image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: 0.5s ease;
  color: #f8f7f9;
}
.rp-round-icon-overlay:hover {
  transform: scale(1.1);
}

.rp-round-icon-overlay:hover .rp-image-overlay {
  opacity: 1;
}

.rp-round-icon-overlay:hover .rp-colored-bg {
  opacity: 1;
}

.rp-top-tracks {
  position: relative;
}
.rp-top-tracks .rp-top-track-title {
  position: absolute;
  right: -2.5%;
  bottom: 5%;
}
.rp-top-tracks .rp-top-track-title h3 {
  writing-mode: vertical-lr;
  transform: scale(-1);
  margin-right: 25px;
}
.rp-top-tracks .rp-tracks-items {
  padding-top: 5%;
  padding-bottom: 5%;
  height: 100%;
}

.rp-track-info {
  padding-left: 3.5%;
  padding-right: 3.5%;
}

.rp-track {
  padding-left: 5%;
}

.rp-top-artists {
  position: relative;
}
.rp-top-artists .rp-top-artist-title-right {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 3%;
}
.rp-top-artists .rp-top-artist-title-left {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 3%;
}
.rp-top-artists .rp-artist-items {
  padding: 35px;
  height: 100%;
  width: 100%;
  transition: visibility 0.4s ease-in-out;
}
.rp-top-artists .rp-top-artist-info {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  animation: pan 15s infinite linear;
  transition: background-image 0.7s ease-in-out;
}
.rp-top-artists .rp-top-artist-info .rp-followers {
  width: 145px;
  height: 145px;
  background-color: #fbcaef;
  position: absolute;
  top: 60%;
  left: 15%;
  border-radius: 50%;
  color: #3b4246;
  transition: opacity 3s ease-in;
}
@media screen and (max-width: 1280px) {
  .rp-top-artists .rp-top-artist-info .rp-followers {
    height: 115px;
    width: 115px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-top-artists .rp-top-artist-info .rp-followers {
    height: 115px;
    width: 115px;
  }
}
@media screen and (max-width: 768px) {
  .rp-top-artists .rp-top-artist-info .rp-followers {
    height: 125px;
    width: 125px;
  }
}
.rp-top-artists .rp-top-artist-info .rp-rank {
  width: 105px;
  height: 105px;
  background-color: #ccf5ac;
  position: absolute;
  top: 5%;
  left: 85%;
  border-radius: 50%;
  color: #3b4246;
  transition: opacity 3s ease-in;
}
@media screen and (max-width: 1280px) {
  .rp-top-artists .rp-top-artist-info .rp-rank {
    height: 95px;
    width: 95px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-top-artists .rp-top-artist-info .rp-rank {
    height: 75px;
    width: 75px;
  }
}
@media screen and (max-width: 768px) {
  .rp-top-artists .rp-top-artist-info .rp-rank {
    height: 75px;
    width: 75px;
  }
}

.rp-top-genre .rp-genre-title {
  margin-right: 5%;
}
.rp-top-genre .rp-genre {
  padding: 2%;
  width: 100%;
  transition: 0.5s;
}
.rp-top-genre .rp-genre #rp-genre {
  display: flex;
  justify-content: center;
  width: 100%;
}
.rp-top-genre .rp-genre h3:hover {
  transform: scale(1.35);
  animation: text-shadow 1.5s ease-in-out infinite;
}

.rp-total-recommendations {
  width: 100%;
  padding: 20px;
}
.rp-total-recommendations .rp-recommendation {
  width: 65%;
}
.rp-total-recommendations .rp-recommendation .rp-recommendation-button {
  width: 25%;
}
.rp-total-recommendations .rp-recommendation .rp-recommendation-button button {
  background-color: #f8f7f9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 10%;
  cursor: none;
  border: 3px solid #fbcaef;
}
.rp-total-recommendations .rp-loading-dots-outer {
  width: 65%;
}
.rp-total-recommendations .rp-loading-dots-outer .rp-loading-dots {
  width: 80px;
  position: relative;
  height: 80px;
}
.rp-total-recommendations .rp-loading-dots-outer .rp-loading-dots div {
  position: absolute;
  width: 15px;
  height: 13px;
  border-radius: 50%;
  background: #fbcaef;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.rp-total-recommendations .rp-recommendations {
  margin-left: 3%;
  margin-top: 3%;
  width: 30%;
}
.rp-total-recommendations .rp-recommendations-toggle-section {
  margin-left: 3%;
  margin-top: 3%;
}
.rp-total-recommendations .rp-recommendations-toggle-section .rp-toggle-background {
  width: 275px;
  height: 125px;
  border-radius: 65px;
  transition: all 0.5s ease-in;
}
.rp-total-recommendations .rp-recommendations-toggle-section .rp-toggle-background .rp-recommendations-inside {
  height: 125px;
  width: 125px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  transition: width 3s ease;
  border-radius: 65px;
  background-size: cover;
  transition: all 0.5s ease-in;
  border-style: solid;
  border-width: 3px;
}
@media screen and (max-width: 1280px) {
  .rp-total-recommendations .rp-recommendations-toggle-section .rp-toggle-background .rp-recommendations-inside {
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-total-recommendations .rp-recommendations-toggle-section .rp-toggle-background .rp-recommendations-inside {
    height: 90px;
    width: 90px;
  }
}
@media screen and (max-width: 768px) {
  .rp-total-recommendations .rp-recommendations-toggle-section .rp-toggle-background .rp-recommendations-inside {
    height: 125px;
    width: 125px;
  }
}
.rp-total-recommendations .rp-recommendations-toggle-section h2 {
  line-height: 24px;
  margin-left: 5px;
}

.rp-currently-playing .rp-currently-playing-title {
  padding: 5%;
}
.rp-currently-playing .rp-currently-playing-title h3 {
  text-align: center;
}
.rp-currently-playing .rp-currently-playing-main {
  margin-bottom: 25px;
}
.rp-currently-playing .rp-currently-playing-main .rp-currently-playing-album-section #rp-loading-svg-normal {
  height: 65px;
  padding: 15px;
  margin-bottom: 10px;
}
.rp-currently-playing .rp-currently-playing-main .rp-currently-playing-album-section #rp-loading-svg-normal rect {
  fill: #fbcaef;
}
.rp-currently-playing .rp-currently-playing-main .rp-currently-playing-slide-bar {
  width: 100%;
  border-bottom: 2px solid #3b4246;
  margin-bottom: 25px;
}
.rp-currently-playing .rp-currently-playing-main .rp-player-controls-background {
  background-color: #fbcaef;
  width: 20vw;
  height: 5vh;
  border-radius: 20px;
}
.rp-currently-playing button {
  cursor: none;
}

.rp-currently-playing-track {
  pointer-events: none;
}
.rp-currently-playing-track .rp-currently-playing-info {
  padding: 2.5%;
  text-align: center;
}
.rp-currently-playing-track #rp-loading-svg,
.rp-currently-playing-track #rp-loading-svg-animated {
  height: 35px;
  margin-bottom: 10px;
}
.rp-currently-playing-track #rp-loading-svg rect,
.rp-currently-playing-track #rp-loading-svg-animated rect {
  fill: #fbcaef;
}
.rp-currently-playing-track #rp-loading-svg-animated-rect-a {
  animation: loading-bar-morph 1s linear 0.1s infinite;
  transform-origin: center;
}
.rp-currently-playing-track #rp-loading-svg-animated-rect-b {
  animation: loading-bar-morph 1s linear 0.2s infinite;
  transform-origin: center;
}
.rp-currently-playing-track #rp-loading-svg-animated-rect-c {
  animation: loading-bar-morph 1s linear 0.4s infinite;
  transform-origin: center;
}
.rp-currently-playing-track #rp-loading-svg-animated-rect-d {
  animation: loading-bar-morph 1s linear 0.2s infinite;
  transform-origin: center;
}
.rp-currently-playing-track #rp-loading-svg-animated-rect-e {
  animation: loading-bar-morph 1s linear 0.1s infinite;
  transform-origin: center;
}

.rp-content {
  margin-top: 150px;
}
@media screen and (max-width: 1280px) {
  .rp-content {
    margin-top: 150px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-content {
    margin-top: 150px;
  }
}
@media screen and (max-width: 768px) {
  .rp-content {
    margin-top: 150px;
  }
}
.rp-content .rp-content-text h3 {
  margin-bottom: 10px;
  font-size: 28px;
}
@media screen and (max-width: 1280px) {
  .rp-content .rp-content-text h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-content .rp-content-text h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .rp-content .rp-content-text h3 {
    font-size: 24px;
  }
}
.rp-content a {
  cursor: none;
}
.rp-content .rp-connect-button {
  margin-top: 35px;
  width: 25vw;
  height: 5vh;
  background-color: #fbcaef;
  border-radius: 35px;
}
.rp-content .rp-connect-button button {
  cursor: none;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 1280px) {
  .rp-content .rp-connect-button button {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-content .rp-connect-button button {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .rp-content .rp-connect-button button {
    font-size: 12px;
  }
}

.rp-contact {
  width: 45vw;
}

form {
  width: 100%;
}
form .rp-label {
  padding-bottom: 5px;
}
form .rp-input {
  background-color: transparent;
  height: 35px;
  border: 1px solid #3b4246;
  border-radius: 10px;
  padding: 5px;
}
form .rp-input:focus {
  outline: none !important;
}
form .rp-textarea {
  background-color: transparent;
  border: 1px solid #3b4246;
  border-radius: 10px;
  padding: 10px;
}
form .rp-textarea:focus {
  outline: none !important;
}
form .rp-submit {
  height: 35px;
  color: #3b4246;
  width: 100px;
  border: none;
  border-radius: 30px;
  background-color: #fbcaef;
  color: #3b4246;
}

button {
  background-color: transparent;
  border: none;
}
button i {
  padding: 10px;
}
button span i {
  padding: 0px;
}
button .rp-green {
  color: #ccf5ac;
}

.rp-heavy-large {
  font-family: briller, sans-serif;
  font-weight: 700;
  font-size: 1.15rem;
}
@media screen and (max-width: 1280px) {
  .rp-heavy-large {
    font-size: 1.15rem;
  }
}
@media screen and (max-width: 1024px) {
  .rp-heavy-large {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 768px) {
  .rp-heavy-large {
    font-size: 1.25rem;
  }
}

.rp-heavy-xlarge {
  font-family: briller, sans-serif;
  font-weight: 700;
  font-size: 1.35rem;
}
@media screen and (max-width: 1280px) {
  .rp-heavy-xlarge {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .rp-heavy-xlarge {
    font-size: 1.15rem;
  }
}
@media screen and (max-width: 768px) {
  .rp-heavy-xlarge {
    font-size: 1.05rem;
  }
}

.rp-heavy-medium {
  font-family: briller, sans-serif;
  font-weight: 700;
  font-size: 22px;
}
@media screen and (max-width: 1280px) {
  .rp-heavy-medium {
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-heavy-medium {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .rp-heavy-medium {
    font-size: 16px;
  }
}

.rp-subscript {
  font-family: briller, sans-serif;
  font-size: 12px;
}
@media screen and (max-width: 1280px) {
  .rp-subscript {
    font-size: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-subscript {
    font-size: 10px;
  }
}
@media screen and (max-width: 768px) {
  .rp-subscript {
    font-size: 16px;
  }
}

.rp-followers-font {
  font-family: briller, sans-serif;
  font-size: 10px;
}
@media screen and (max-width: 1280px) {
  .rp-followers-font {
    font-size: 8px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-followers-font {
    font-size: 8px;
  }
}
@media screen and (max-width: 768px) {
  .rp-followers-font {
    font-size: 10px;
  }
}

.rp-light-small {
  font-family: briller, sans-serif;
  font-weight: 400;
  font-size: 0.85rem;
}
@media screen and (max-width: 1280px) {
  .rp-light-small {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .rp-light-small {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 768px) {
  .rp-light-small {
    font-size: 16px;
  }
}

.rp-heavy-small {
  font-family: briller, sans-serif;
  font-weight: 700;
  font-size: 0.95rem;
}
@media screen and (max-width: 1280px) {
  .rp-heavy-small {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 1024px) {
  .rp-heavy-small {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 768px) {
  .rp-heavy-small {
    font-size: 16px;
  }
}

.rp-heavy-xsmall {
  font-family: briller, sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
}
@media screen and (max-width: 1280px) {
  .rp-heavy-xsmall {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 1024px) {
  .rp-heavy-xsmall {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 768px) {
  .rp-heavy-xsmall {
    font-size: 16px;
  }
}

.rp-paragraph-text {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
}
@media screen and (max-width: 1280px) {
  .rp-paragraph-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-paragraph-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .rp-paragraph-text {
    font-size: 14px;
  }
}

.rp-disclaimer {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 12px;
}

ul {
  list-style-type: circle;
  list-style-position: outside;
}
ul li {
  margin-left: 2.5%;
  margin-bottom: 8px;
}
ul strong {
  text-decoration: underline;
}

.rp-underline {
  text-decoration: underline;
}

.rp-paragraph-text-large {
  font-family: "Press Start 2P", cursive;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
@media screen and (max-width: 1280px) {
  .rp-paragraph-text-large {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .rp-paragraph-text-large {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .rp-paragraph-text-large {
    font-size: 22px;
  }
}

.rp-title {
  font-family: "Kankin", sans-serif;
  font-size: 32px;
}

i {
  font-size: 24px;
  color: #3b4246;
}
@media screen and (max-width: 1280px) {
  i {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  i {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  i {
    font-size: 24px;
  }
}

.rp-spotify-logo {
  font-size: 32px;
  margin-top: 25px;
}

.rp-bold {
  font-weight: 600;
}

.rp-red {
  color: #f93943;
}

.rp-left {
  text-align: left;
}

.rp-plain {
  text-decoration: none !important;
  color: #3b4246;
  cursor: none;
}
.rp-plain:hover {
  color: #f93943;
}

.rp-plain-underline {
  text-decoration: underline;
  color: #3b4246;
  cursor: none;
}
.rp-plain-underline:hover {
  color: #f2a6df;
}

.rp-loading-dots div:nth-child(1) {
  left: 8px;
  animation: loading-dots-a 0.6s infinite;
}

.rp-loading-dots div:nth-child(2) {
  left: 8px;
  animation: loading-dots-b 0.6s infinite;
}

.rp-loading-dots div:nth-child(3) {
  left: 32px;
  animation: loading-dots-b 0.6s infinite;
}

.rp-loading-dots div:nth-child(4) {
  left: 56px;
  animation: loading-dots-c 0.6s infinite;
}

@keyframes text-shadow {
  0% {
    transform: translateY(-1em);
    transform: scale(1.35);
    text-shadow: 0 0 0 #ccf5ac, 0 0 0 #fbcaef, 0 0 0 #f2a6df, 0 0 0 #c2f970;
  }
  20% {
    transform: scale(1.55);
    transform: translateY(-0.5em);
    text-shadow: 0 0.125em 0 #ccf5ac, 0 0.25em 0 #fbcaef, 0 -0.125em 0 #f2a6df, 0 -0.25em 0 #c2f970;
  }
  40% {
    transform: translateY(0);
    transform: scale(1);
    text-shadow: 0 -0.0625em 0 #ccf5ac, 0 -0.125em 0 #fbcaef, 0 0.0625em 0 #f2a6df, 0 0.125em 0 #c2f970;
  }
  60% {
    transform: translateY(0.5em);
    transform: scale(1.55);
    text-shadow: 0 0.03125em 0 #ccf5ac, 0 0.0625em 0 #fbcaef, 0 -0.03125em 0 #f2a6df, 0 -0.0625em 0 #c2f970;
  }
  80% {
    transform: translateY(1em);
    transform: scale(1.35);
    text-shadow: 0 0 0 #ccf5ac, 0 0 0 #fbcaef, 0 0 0 #f2a6df, 0 0 0 #c2f970;
  }
}
@keyframes loading-bar-morph {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(0.7);
  }
  75% {
    transform: scaleY(0.15);
  }
}
@keyframes pan {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 0% 100%;
  }
  75% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes loading-dots-a {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-dots-b {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes loading-dots-c {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

