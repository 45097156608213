@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&family=Press+Start+2P&family=Quicksand:wght@300;400;500;600;700&family=Syncopate:wght@400;700&display=swap");
@import url("https://use.typekit.net/nxj3kdm.css");
@import url("https://fonts.cdnfonts.com/css/kankin");

$start: "Press Start 2P", cursive;
$menu: "Quicksand", sans-serif;
$content: "Jura", sans-serif;
$bri: briller, sans-serif;
$kankin: "Kankin", sans-serif;
$cursor: url("../Assets/round_cursor.svg"), auto;
$cursor_border: url("../Assets/round_cursor_border.svg"), auto;

$green: #ccf5ac;
$bright-green: #c2f970;
$pink: #fbcaef;
$red: #f93943;
$dark-pink: #f2a6df;
$dark: #3b4246;
$light: #f8f7f9;
$border-box: 1px solid $dark;
$cursor_prop: none;
$header_padding: 54px;
$round_radius: 50%;
$round_image_radius: 0%;
$barely_there_radius: 0px;
$menu_radius: 30px;
$scale_up: 1.10;
$scale_genre: 1.35;
$scale_down: 0.9;
$scale_negative: -1;

// FONTS-SIZE
$main_font_sm: 16px;
$main_font_md: 16px;
$main_font_lg: 16px;
$main_font_xl: 16px;

$menu_font_sm: 16px;
$menu_font_md: 16px;
$menu_font_lg: 16px;
$menu_font_xl: 16px;

$disclaimer: 12px;
$title: 32px;
$icon: 24px;

$heavy_sm: 16px;
$heavy_md: 16px;
$heavy_lg: 16px;
$heavy_xl: 14px;

$margin_sm: 150px;
$margin_md: 150px;
$margin_lg: 150px;
$margin_xl: 150px;

$content_sm: 24px;
$content_md: 24px;
$content_lg: 24px;
$content_xl: 28px;

$heavy_small_sm: 16px;
$heavy_small_md: 0.7rem;
$heavy_small_lg: 0.85rem;
$heavy_small_xl: 0.95rem;

$heavy_xsmall_sm: 16px;
$heavy_xsmall_md: 0.55rem;
$heavy_xsmall_lg: 0.65rem;
$heavy_xsmall_xl: 0.75rem;

$light_small_sm: 16px;
$light_small_md: 0.7rem;
$light_small_lg: 0.75rem;
$light_small_xl: 0.85rem;

$medium_small_sm: 16px;
$medium_small_md: 10px;
$medium_small_lg: 10px;
$medium_small_xl: 12px;

$heavy_medium_sm: 16px;
$heavy_medium_md: 16px;
$heavy_medium_lg: 16px;
$heavy_medium_xl: 22px;

$heavy_large_sm: 1.25rem;
$heavy_large_md: 1.25rem;
$heavy_large_lg: 1.15rem;
$heavy_large_xl: 1.15rem;

$heavy_xlarge_sm: 1.05rem;
$heavy_xlarge_md: 1.15rem;
$heavy_xlarge_lg: 1.25rem;
$heavy_xlarge_xl: 1.35rem;

$paragraph_large_sm: 22px;
$paragraph_large_md: 16px;
$paragraph_large_lg: 18px;
$paragraph_large_xl: 22px;

$paragraph_sm: 14px;
$paragraph_md: 16px;
$paragraph_lg: 16px;
$paragraph_xl: 18px;

$paragraphx_sm: 12px;
$paragraphx_md: 14px;
$paragraphx_lg: 14px;
$paragraphx_xl: 16px;

$genre_sm: 120px;
$genre_md: 120px;
$genre_lg: 120px;
$genre_xl: 120px;

// TOP-SONGS
$songs_width_sm: 125px;
$songs_width_md: 125px;
$songs_width_lg: 125px;
$songs_width_xl: 125px;

$songs_margin_sm: 125px;
$songs_margin_md: 125px;
$songs_margin_lg: 125px;
$songs_margin_xl: 125px;

// IMAGES
$rounded_plain_width_sm: 125px;
$rounded_plain_width_md: 105px;
$rounded_plain_width_lg: 125px;
$rounded_plain_width_xl: 165px;

$rounded_width_sm: 125px;
$rounded_width_md: 90px;
$rounded_width_lg: 100px;
$rounded_width_xl: 125px;

$rounded_small_width_sm: 200px;
$rounded_small_width_md: 200px;
$rounded_small_width_lg: 200px;
$rounded_small_width_xl: 200px;

$followers_sm: 125px;
$followers_md: 115px;
$followers_lg: 115px;
$followers_xl: 145px;

$rank_sm: 75px;
$rank_md: 75px;
$rank_lg: 95px;
$rank_xl: 105px;

$followers_font_sm: 10px;
$followers_font_md: 8px;
$followers_font_lg: 8px;
$followers_font_xl: 10px;

// SCREEN SIZES
$xsmall_screen: 450px;
$small_screen: 768px;
$medium_screen: 1024px;
$large_screen: 1280px;

$base_font: 16px;

// 1280+
@mixin responsive($breakpoint) {

    // From 1025 to 1280
    @if $breakpoint ==large {
        @media screen and (max-width: $large_screen) {
            @content;
        }
    }

    // From 769 to 1024
    @if $breakpoint ==medium {
        @media screen and (max-width: $medium_screen) {
            @content;
        }
    }

    // Up to 768
    @if $breakpoint ==small {
        @media screen and (max-width: $small_screen) {
            @content;
        }
    }

    // Up to 450
    @if $breakpoint ==xs {
        @media screen and (max-width: $xsmall_screen) {
            @content;
        }
    }
}

/*
    @include responsive(large) {
       
    }

    @include responsive(medium) {
       
    }

    @include responsive(small) {
        
    }
*/

//BODY LAYOUT

html,
body {
    font-size: $base_font;
    background-color: $green;
}

body,
.container,
#root {
    height: 100vh;
    color: $dark;
    cursor: $cursor_prop;
    background-color: $green;
}

.rp-main-container {
    height: 100vh;

    background: {
        color: $green;
        image: url("../Assets/background.svg");
        attachment: fixed;
        repeat: no-repeat;
        size: cover;
    }
}

.rp-no-bg {
    background-image: none;
}


.rp-full-height {
    height: 100vh;
}

.rp-privacy-content {
    overflow: scroll;
    height: 85vh;
}

.rp-view {
    height: 100%;


    .rp-section {

        .rp-half-height {
            height: 50%;
        }

        .rp-three-fifths-height {
            height: 65%;
        }

        .rp-two-fifths-height {
            height: 35%;
        }
    }
}

.rp-border-left {
    border-left: $border-box;
    position: relative;
    z-index: 21;
}

.rp-border-right {
    border-right: $border-box;
    position: relative;
    z-index: 21;
}

.rp-border-top {
    border-top: $border-box;
    position: relative;
    z-index: 21;
}

.rp-border-bottom {
    border-bottom: $border-box;
    position: relative;
    z-index: 21;
}

.is-absolute {
    position: absolute;
}

.is-solid-background {
    background-color: transparent;
}

.rp-cursor {
    background-color: $red;
    position: fixed;
    z-index: 1100;
    pointer-events: none;

    .rp-cursor-content {
        padding: 10px;
    }
}

// HEADER

.rp-header {
    position: relative;
    height: 100px;
    background-color: $green;
    z-index: 1000;
    padding-left: $header_padding;
    padding-right: $header_padding;
    cursor: $cursor_prop;

    .rp-divider {
        border-bottom: 1px solid $dark;
    }

    .rp-menu {
        padding-right: 30px;

        .rp-menu-item {
            cursor: $cursor_prop;
            text-decoration: none !important;
            color: $dark;
            margin: 10px;

            .rp-menu-item-text {
                color: $dark;
                padding: 5px 10px 5px 10px;
                border: 2px solid transparent;
                background-color: $green;

                font: {
                    family: $menu;
                    size: 16px;
                    weight: 600;
                }

                &:hover,
                &:active {
                    border: 2px solid transparent;
                    border-radius: $menu_radius;
                    background-color: $red;
                    color: $light;
                }
            }

            .rp-selected {
                border: 2px solid transparent;
                border-radius: $menu_radius;
                background-color: $red;
                color: $light;
            }
        }

        button {
            cursor: $cursor_prop;
            border-radius: $menu_radius;

            &:hover {
                background-color: $red;

                i {
                    color: $light;
                }
            }
        }
    }
}

// ICONS AND IMAGES

.rp-rounded-icon {
    position: relative;

    div {
        pointer-events: none;
    }

    img {
        position: relative;
        z-index: 36;
        width: $rounded_width_xl;
        max-width: $rounded_width_xl;
        min-width: $rounded_width_xl;
        object-fit: fill;
        border-radius: $barely_there_radius;
        transition: all 0.5s ease;
        pointer-events: none;

        @include responsive(large) {

            width: $rounded_width_lg;
            min-width: $rounded_width_lg;
            max-width: $rounded_width_lg;
        }

        @include responsive(medium) {
            width: $rounded_width_md;
            max-width: $rounded_width_md;
            min-width: $rounded_width_md;
        }

        @include responsive(small) {
            width: $rounded_width_sm;
            max-width: $rounded_width_sm;
            min-width: $rounded_width_sm;
        }
    }

    .rp-background {
        width: $rounded_width_xl;
        height: $rounded_width_xl;
        border-radius: $round_image_radius;
        background-color: $red;
        padding: 0px;
        margin: 0px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.5s ease;

        @include responsive(large) {
            height: $rounded_width_lg;
            width: $rounded_width_lg;
        }

        @include responsive(medium) {
            height: $rounded_width_md;
            width: $rounded_width_md;
        }

        @include responsive(small) {
            height: $rounded_width_sm;
            width: $rounded_width_sm;
        }
    }
}

.rp-rounded-icon:hover .rp-rounded-icon-img-shrink {
    transform: scale($scale_down);
    border-radius: $round_image_radius;
}

.rp-rounded-icon:hover img {
    border: 2px solid $light;
}

.rp-rounded-icon:hover .rp-background {
    transform: scale($scale_up);
    border-radius: $round_image_radius;
}

.rp-round-icon-overlay {
    img {
        width: $rounded_width_xl;
        height: $rounded_width_xl;
        object-fit: cover;
        border-radius: $round_image_radius;

        @include responsive(large) {
            height: $rounded_width_lg;
            width: $rounded_width_lg;
        }

        @include responsive(medium) {
            height: $rounded_width_md;
            width: $rounded_width_md;
        }

        @include responsive(small) {
            height: $rounded_width_sm;
            width: $rounded_width_sm;
        }
    }
}

.rp-round-icon {
    img {
        width: $rounded_small_width_xl;
        height: $rounded_small_width_xl;
        object-fit: cover;
        border-radius: $round_image_radius;
        margin-left: 20px;

        @include responsive(large) {
            height: $rounded_small_width_lg;
            width: $rounded_small_width_lg;
        }

        @include responsive(medium) {
            height: $rounded_small_width_md;
            width: $rounded_small_width_md;
        }

        @include responsive(small) {
            height: $rounded_small_width_sm;
            width: $rounded_small_width_sm;
        }
    }
}

.rp-rounded-icon-plain {
    img {
        width: $rounded_plain_width_xl;
        height: $rounded_plain_width_xl;
        object-fit: cover;
        border-radius: $barely_there_radius;
        margin-left: 20px;

        @include responsive(large) {
            height: $rounded_plain_width_lg;
            width: $rounded_plain_width_lg;
        }

        @include responsive(medium) {
            height: $rounded_plain_width_md;
            width: $rounded_plain_width_md;
        }

        @include responsive(small) {
            height: $rounded_plain_width_sm;
            width: $rounded_plain_width_sm;
        }
    }
}

.rp-round-icon-overlay {
    position: relative;
    transition: 0.5s ease;

    .rp-colored-bg {
        width: $rounded_width_xl;
        height: $rounded_width_xl;
        border-radius: $round_image_radius;
        background-color: $red;
        padding: 0px;
        margin: 0px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: 0.5s ease;

        @include responsive(large) {
            height: $rounded_width_lg;
            width: $rounded_width_lg;
        }

        @include responsive(medium) {
            height: $rounded_width_md;
            width: $rounded_width_md;
        }

        @include responsive(small) {
            height: $rounded_width_sm;
            width: $rounded_width_sm;
        }
    }

    .rp-image-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        opacity: 0;
        transition: 0.5s ease;
        color: $light;
    }

    &:hover {
        transform: scale($scale_up);
    }
}

.rp-round-icon-overlay:hover .rp-image-overlay {
    opacity: 1;
}

.rp-round-icon-overlay:hover .rp-colored-bg {
    opacity: 1;
}

// TRACKS

.rp-top-tracks {
    position: relative;

    .rp-top-track-title {
        position: absolute;
        right: -2.5%;
        bottom: 5%;

        h3 {
            writing-mode: vertical-lr;
            transform: scale($scale_negative);
            margin-right: 25px;
        }
    }

    .rp-tracks-items {
        padding-top: 5%;
        padding-bottom: 5%;
        height: 100%;
    }
}


.rp-track-info {
    padding-left: 3.5%;
    padding-right: 3.5%;
}

.rp-track {
    padding-left: 5%;
}

// ARTISTS

.rp-top-artists {
    position: relative;

    .rp-top-artist-title-right {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 3%;
    }

    .rp-top-artist-title-left {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 3%;
    }

    .rp-artist-items {
        padding: 35px;
        height: 100%;
        width: 100%;
        transition: visibility 0.4s ease-in-out;
    }

    .rp-top-artist-info {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: scroll;
        animation: pan 15s infinite linear;
        transition: background-image 0.7s ease-in-out;

        .rp-followers {
            width: $followers_xl;
            height: $followers_xl;
            background-color: $pink;
            position: absolute;
            top: 60%;
            left: 15%;
            border-radius: $round_radius;
            color: $dark;
            transition: opacity 3s ease-in;

            @include responsive(large) {
                height: $followers_lg;
                width: $followers_lg;
            }

            @include responsive(medium) {
                height: $followers_md;
                width: $followers_md;
            }

            @include responsive(small) {
                height: $followers_sm;
                width: $followers_sm;
            }
        }

        .rp-rank {
            width: $rank_xl;
            height: $rank_xl;
            background-color: $green;
            position: absolute;
            top: 5%;
            left: 85%;
            border-radius: $round_radius;
            color: $dark;
            transition: opacity 3s ease-in;

            @include responsive(large) {
                height: $rank_lg;
                width: $rank_lg;
            }

            @include responsive(medium) {
                height: $rank_md;
                width: $rank_md;
            }

            @include responsive(small) {
                height: $rank_sm;
                width: $rank_sm;
            }
        }
    }
}

// GENRE

.rp-top-genre {
    .rp-genre-title {
        margin-right: 5%;
    }

    .rp-genre {
        padding: 2%;

        width: 100%;

        #rp-genre {

            display: flex;
            justify-content: center;
            width: 100%;
        }

        transition: 0.5s;

        h3 {
            &:hover {
                transform: scale($scale_genre);
                animation: text-shadow 1.5s ease-in-out infinite;
            }
        }
    }
}

// RECOMMENDATIONS

.rp-total-recommendations {
    width: 100%;
    padding: 20px;

    .rp-recommendation {
        width: 65%;

        .rp-recommendation-button {
            width: 25%;

            button {
                background-color: $light;
                width: 50px;
                height: 50px;
                border-radius: $round_radius;
                margin-left: 10%;
                cursor: $cursor_prop;


                border: 3px solid $pink;
            }
        }

    }

    .rp-loading-dots-outer {
        width: 65%;

        .rp-loading-dots {
            width: 80px;
            position: relative;
            height: 80px;

            div {
                position: absolute;
                width: 15px;
                height: 13px;
                border-radius: $round_radius;
                background: $pink;
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
            }
        }
    }

    .rp-recommendations {
        margin-left: 3%;
        margin-top: 3%;
        width: 30%;
    }

    .rp-recommendations-toggle-section {
        margin-left: 3%;
        margin-top: 3%;

        .rp-toggle-background {
            width: 275px;
            height: 125px;
            border-radius: 65px;
            transition: all 0.5s ease-in;

            .rp-recommendations-inside {
                height: $rounded_width_xl;
                width: $rounded_width_xl;
                margin-left: 2.5px;
                margin-right: 2.5px;
                transition: width 3s ease;
                border-radius: 65px;
                background-size: cover;
                transition: all 0.5s ease-in;
                border-style: solid;
                border-width: 3px;

                @include responsive(large) {
                    height: $rounded_width_lg;
                    width: $rounded_width_lg;
                }

                @include responsive(medium) {
                    height: $rounded_width_md;
                    width: $rounded_width_md;
                }

                @include responsive(small) {
                    height: $rounded_width_sm;
                    width: $rounded_width_sm;
                }
            }
        }

        h2 {
            line-height: 24px;
            margin-left: 5px;
        }
    }
}

// CURRENTLY PLAYING

.rp-currently-playing {
    .rp-currently-playing-title {
        padding: 5%;

        h3 {
            text-align: center;
        }
    }

    .rp-currently-playing-main {
        .rp-currently-playing-album-section {
            #rp-loading-svg-normal {
                height: 65px;
                padding: 15px;
                margin-bottom: 10px;

                rect {
                    fill: $pink;
                }
            }
        }

        .rp-currently-playing-slide-bar {
            width: 100%;
            border-bottom: 2px solid $dark;
            margin-bottom: 25px;
        }

        .rp-player-controls-background {
            background-color: $pink;
            width: 20vw;
            height: 5vh;
            border-radius: 20px;
        }

        margin-bottom: 25px;
    }

    button {
        cursor: $cursor_prop;
    }
}

.rp-currently-playing-track {
    pointer-events: none;

    .rp-currently-playing-info {
        padding: 2.5%;
        text-align: center;
    }

    #rp-loading-svg,
    #rp-loading-svg-animated {
        height: 35px;
        margin-bottom: 10px;

        rect {
            fill: $pink;
        }
    }

    #rp-loading-svg-animated {
        &-rect-a {
            animation: loading-bar-morph 1s linear 0.1s infinite;
            transform-origin: center;
        }

        &-rect-b {
            animation: loading-bar-morph 1s linear 0.2s infinite;
            transform-origin: center;
        }

        &-rect-c {
            animation: loading-bar-morph 1s linear 0.4s infinite;
            transform-origin: center;
        }

        &-rect-d {
            animation: loading-bar-morph 1s linear 0.2s infinite;
            transform-origin: center;
        }

        &-rect-e {
            animation: loading-bar-morph 1s linear 0.1s infinite;
            transform-origin: center;
        }
    }
}

// WELCOME AND ERROR PAGE

.rp-content {
    margin-top: $margin_xl;

    @include responsive(large) {
        margin-top: $margin_lg;
    }

    @include responsive(medium) {
        margin-top: $margin_md;
    }

    @include responsive(small) {
        margin-top: $margin_sm;
    }

    .rp-content-text {
        h3 {
            margin-bottom: 10px;
            font-size: $content_xl;

            @include responsive(large) {
                font-size: $content_lg
            }

            @include responsive(medium) {
                font-size: $content_md;
            }

            @include responsive(small) {
                font-size: $content_sm;
            }
        }
    }

    a {
        cursor: $cursor_prop;
    }

    .rp-connect-button {
        margin-top: 35px;
        width: 25vw;
        height: 5vh;
        background-color: $pink;
        border-radius: 35px;

        button {
            cursor: $cursor_prop;
            font-family: $menu;
            font-weight: 600;
            text-align: center;
            font-size: $paragraphx_xl;

            @include responsive(large) {
                font-size: $paragraphx_md;
            }

            @include responsive(medium) {
                font-size: $paragraphx_sm;
            }

            @include responsive(small) {
                font-size: $paragraphx_sm;
            }
        }
    }
}

// FORMS

.rp-contact {
    width: 45vw;
}

form {
    width: 100%;

    .rp-label {
        padding-bottom: 5px;
    }

    .rp-input {
        background-color: transparent;
        height: 35px;
        border: 1px solid $dark;
        border-radius: 10px;
        padding: 5px;

        &:focus {
            outline: none !important;
        }
    }

    .rp-textarea {

        background-color: transparent;
        border: 1px solid $dark;
        border-radius: 10px;
        padding: 10px;

        &:focus {
            outline: none !important;
        }
    }

    .rp-submit {
        height: 35px;
        color: $dark;
        width: 100px;
        border: none;
        border-radius: $menu_radius;
        background-color: $pink;
        color: $dark;
    }
}


// BUTTONS

button {
    background-color: transparent;
    border: none;

    i {
        padding: 10px;
    }

    span {
        i {
            padding: 0px;
        }
    }

    .rp-green {
        color: $green;
    }
}


// FONT AND ICON SIZING

.rp-heavy-large {
    font-family: $bri;
    font-weight: 700;
    font-size: $heavy_large_xl;

    @include responsive(large) {
        font-size: $heavy_large_lg;
    }

    @include responsive(medium) {
        font-size: $heavy_large_md;
    }

    @include responsive(small) {
        font-size: $heavy_large_sm;
    }
}

.rp-heavy-xlarge {
    font-family: $bri;
    font-weight: 700;
    font-size: $heavy_xlarge_xl;

    @include responsive(large) {
        font-size: $heavy_xlarge_lg;
    }

    @include responsive(medium) {
        font-size: $heavy_xlarge_md;
    }

    @include responsive(small) {
        font-size: $heavy_xlarge_sm;
    }
}

.rp-heavy-medium {
    font-family: $bri;
    font-weight: 700;
    font-size: $heavy_medium_xl;

    @include responsive(large) {
        font-size: $heavy_medium_lg;
    }

    @include responsive(medium) {
        font-size: $heavy_medium_md;
    }

    @include responsive(small) {
        font-size: $heavy_medium_sm;
    }
}

.rp-subscript {
    font-family: $bri;
    font-size: $medium_small_xl;

    @include responsive(large) {
        font-size: $medium_small_lg;
    }

    @include responsive(medium) {
        font-size: $medium_small_md;
    }

    @include responsive(small) {
        font-size: $medium_small_sm;
    }
}

.rp-followers-font {
    font-family: $bri;
    font-size: $followers_font_xl;

    @include responsive(large) {
        font-size: $followers_font_lg;
    }

    @include responsive(medium) {
        font-size: $followers_font_md;
    }

    @include responsive(small) {
        font-size: $followers_font_sm;
    }
}

.rp-light-small {
    font-family: $bri;
    font-weight: 400;
    font-size: $light_small_xl;

    @include responsive(large) {
        font-size: $light_small_lg;
    }

    @include responsive(medium) {
        font-size: $light_small_md
    }

    @include responsive(small) {
        font-size: $light_small_sm;
    }
}

.rp-heavy-small {
    font-family: $bri;
    font-weight: 700;
    font-size: $heavy_small_xl;


    @include responsive(large) {
        font-size: $heavy_small_lg;
    }

    @include responsive(medium) {
        font-size: $heavy_small_md;
    }

    @include responsive(small) {
        font-size: $heavy_small_sm;
    }
}

.rp-heavy-xsmall {
    font-family: $bri;
    font-weight: 700;
    font-size: $heavy_xsmall_xl;


    @include responsive(large) {
        font-size: $heavy_xsmall_lg;
    }

    @include responsive(medium) {
        font-size: $heavy_xsmall_md;
    }

    @include responsive(small) {
        font-size: $heavy_xsmall_sm;
    }
}

.rp-paragraph-text {
    font-family: $menu;
    font-weight: 600;
    font-size: $paragraph_xl;
    text-align: center;
    line-height: $paragraph_xl;


    @include responsive(large) {
        font-size: $paragraph_lg;
    }

    @include responsive(medium) {
        font-size: $paragraph_md;
    }

    @include responsive(small) {
        font-size: $paragraph_sm;
    }
}


.rp-disclaimer {
    font-family: $menu;
    font-weight: 700;
    font-size: $disclaimer;

}

ul {
    list-style-type: circle;
    list-style-position: outside;

    li {
        margin-left: 2.5%;
        margin-bottom: 8px;
    }

    strong {
        text-decoration: underline;
    }
}

.rp-underline {
    text-decoration: underline;
}

.rp-paragraph-text-large {
    font-family: $start;
    font-weight: 700;
    font-size: $paragraph_large_xl;
    text-align: center;

    @include responsive(large) {
        font-size: $paragraph_large_lg;
    }

    @include responsive(medium) {
        font-size: $paragraph_large_md;
    }

    @include responsive(small) {
        font-size: $paragraph_large_sm;
    }
}

.rp-title {
    font-family: $kankin;
    font-size: $title;
}

i {
    font-size: $icon;
    color: $dark;

    @include responsive(large) {
        font-size: $icon;
    }

    @include responsive(medium) {
        font-size: $icon;
    }

    @include responsive(small) {
        font-size: $icon;
    }
}

.rp-spotify-logo {
    font-size: 32px;
    margin-top: 25px;
}

.rp-bold {
    font-weight: 600;
}

.rp-red {
    color: $red;
}

.rp-left {
    text-align: left;
}

.rp-plain {
    text-decoration: none !important;
    color: $dark;
    cursor: none;

    &:hover {
        color: $red;
    }
}

.rp-plain-underline {
    text-decoration: underline;
    color: $dark;
    cursor: none;

    &:hover {
        color: $dark-pink;
    }
}


// ANIMATION KEYFRAMES

.rp-loading-dots div:nth-child(1) {
    left: 8px;
    animation: loading-dots-a 0.6s infinite;
}

.rp-loading-dots div:nth-child(2) {
    left: 8px;
    animation: loading-dots-b 0.6s infinite;
}

.rp-loading-dots div:nth-child(3) {
    left: 32px;
    animation: loading-dots-b 0.6s infinite;
}

.rp-loading-dots div:nth-child(4) {
    left: 56px;
    animation: loading-dots-c 0.6s infinite;
}

@keyframes text-shadow {
    0% {
        transform: translateY(-1em);
        transform: scale(1.35);
        text-shadow: 0 0 0 $green, 0 0 0 $pink, 0 0 0 $dark-pink,
            0 0 0 $bright-green;
    }

    20% {
        transform: scale(1.55);
        transform: translateY(-0.5em);
        text-shadow: 0 0.125em 0 $green, 0 0.25em 0 $pink,
            0 -0.125em 0 $dark-pink, 0 -0.25em 0 $bright-green;
    }

    40% {
        transform: translateY(0);
        transform: scale(1);
        text-shadow: 0 -0.0625em 0 $green, 0 -0.125em 0 $pink,
            0 0.0625em 0 $dark-pink, 0 0.125em 0 $bright-green;
    }

    60% {
        transform: translateY(0.5em);
        transform: scale(1.55);
        text-shadow: 0 0.03125em 0 $green, 0 0.0625em 0 $pink,
            0 -0.03125em 0 $dark-pink, 0 -0.0625em 0 $bright-green;
    }

    80% {
        transform: translateY(1em);
        transform: scale(1.35);
        text-shadow: 0 0 0 $green, 0 0 0 $pink, 0 0 0 $dark-pink,
            0 0 0 $bright-green;
    }
}

@keyframes loading-bar-morph {
    0% {
        transform: scaleY(1);
    }

    25% {
        transform: scaleY(0.3);
    }

    50% {
        transform: scaleY(0.7);
    }

    75% {
        transform: scaleY(0.15);
    }
}

@keyframes pan {
    0% {
        background-position: 0% 0%;
    }

    25% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 0% 100%;
    }

    75% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 0% 0%;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes loading-dots-a {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loading-dots-b {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

@keyframes loading-dots-c {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}